// @ts-check
import React from "react"

const Creandum = (/** @type {React.SVGAttributes} */ { ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 235 235"
    {...props}
  >
    <circle cx="117.5" cy="117.5" r="117.5" fill="#F96443" />
    <path
      d="M30.9012 127.74c-5.5994 0-9.7508-4.311-9.7508-9.762v-.051c0-5.4 4.0746-9.827 9.9173-9.827 3.5877 0 5.7275 1.19 7.4957 2.93l-2.6651 3.071c-1.4607-1.331-2.9598-2.15-4.8562-2.15-3.2033 0-5.5096 2.662-5.5096 5.912v.051c0 3.25 2.2551 5.962 5.5096 5.962 2.1782 0 3.498-.87 4.9971-2.226l2.6652 2.687c-1.9604 2.098-4.1387 3.403-7.8032 3.403zM57.655 127.408l-4.0746-6.078h-3.293v6.078h-4.177V108.42h8.6872c4.4846 0 7.1754 2.354 7.1754 6.269v.052c0 3.07-1.6529 4.99-4.0746 5.885l4.6512 6.782H57.655zm.0897-12.501c0-1.791-1.2557-2.713-3.293-2.713h-4.1515v5.451h4.2412c2.0373 0 3.2033-1.088 3.2033-2.687v-.051zM70.2109 127.408V108.42h14.3379v3.71H74.3624v3.852h8.9692v3.71h-8.9692v3.992h10.3274v3.711H70.2109v.013zM107.216 127.408l-1.743-4.261h-8.0467l-1.7425 4.261H91.417l8.1619-19.116h3.8571l8.149 19.129h-4.369v-.013zm-5.754-14.1l-2.5365 6.154h5.0485l-2.512-6.154zM132.06 127.408l-9.213-12.066v12.066h-4.125V108.42h3.856l8.906 11.695V108.42h4.125v18.988h-3.549zM152.126 127.408h-7.419V108.42h7.419c5.971 0 10.109 4.094 10.109 9.443v.051c-.012 5.348-4.138 9.494-10.109 9.494zm5.727-9.494c0-3.365-2.306-5.72-5.727-5.72h-3.229v11.452h3.229c3.421 0 5.727-2.303 5.727-5.668v-.064zM177.636 127.715c-5.112 0-8.226-2.853-8.226-8.432V108.42h4.177v10.748c0 3.096 1.551 4.695 4.1 4.695 2.55 0 4.101-1.548 4.101-4.555v-10.875h4.177v10.709c.013 5.745-3.229 8.573-8.329 8.573zM209.157 127.408v-12.386l-5.356 8.112h-.102l-5.292-8.035v12.309h-4.1V108.42h4.51l4.997 8.035 4.997-8.035h4.51v18.988h-4.164z"
      fill="#fff"
    />
  </svg>
)

export default Creandum
